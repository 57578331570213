import React, { Component } from "react";


class HeroOlive extends Component {
    render(){
        return(
            <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape}`} id="home" data-bg-color="#2d3e50">
                <div className="container h-100">
                    <div className="row">
                        <div className="col-lg-7 h-100">
                            <div className="banner-text">
                                <div className="banner-table-cell">
                                    <h3><span style={{ fontSize: 32, marginBottom: 0, color: "#fff" }}>Introducing</span></h3>
                                    <h1><span className="theme-color">M8thub</span></h1>
                                    <h3><span style={{ fontSize: 24, marginTop: -100, color: "#fff" }}>Now in iOS Beta 2.0</span></h3>
                                    <p>M8thub is a platform where you can monetize your videos and livestreams. Join our beta testing now!</p>
                                    <div className="banner-buttons">
                                        {/* <button type="button" className="button-default button-olive">Download</button>
                                        <a className="button-default button-white" href="/" role="button">Learn more</a> */}
                                    </div>
                                </div>
                            </div>

                            <div className="banner-apps">
                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-apple"></i>
                                        <h4>ios</h4>
                                        <h3>Now</h3>
                                    </div>
                                </div>

                                {/* Start Sinhle app */}
                                {/* <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-cloud-download"></i>
                                        <h4>Download</h4>
                                        <h3>Soon</h3>
                                    </div>
                                </div> */}

                                {/* Start Sinhle app */}
                                <div className="single-app">
                                    <div className="single-app-table-cell">
                                        <i className="zmdi zmdi-android"></i>
                                        <h4>Android</h4>
                                        <h3>Soon</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-product-image text-right">
                                {/* <img className="image-1" src="/assets/images/app/banner-mobile.png" alt="App Landing"/> */}
                                {/* <img className="image-2" src="/assets/images/app/banner-mobile-2.png" alt="App Landing"/>
                                <img className="image-3" src="/assets/images/app/banner-mobile-3.png" alt="App Landing"/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeroOlive;








