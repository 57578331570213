import React, { Component } from "react";


class HeroOlive2 extends Component {
    render(){
        return(
            <div className={`slider-area bg-color ${this.props.horizontal} ${this.props.bgshape} ${this.props.height}`} id="home" data-bg-color="#2d3e50" style={{ height: "500px !important" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="banner-text">
                                {/* <div className="banner-table-cell">
                                    <h1><span className="theme-color">M8thub </span><br/>is coming  <br/>soon</h1>
                                    <p>We are launching M8thub very soon. After months of public testing, We have listened to your needs. M8thub has been re-designed from the ground up based on our user needs.</p>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="banner-product-image text-right">
                                {/* <img className="image-1" src="/assets/images/app/banner-mobile.png" alt="App Landing"/> */}
                                {/* <img className="image-2" src="/assets/images/app/banner-mobile-2.png" alt="App Landing"/>
                                <img className="image-3" src="/assets/images/app/banner-mobile-3.png" alt="App Landing"/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HeroOlive2;








