import React, { Component } from "react";



class Terms extends Component{
    render(){


        return (
            <div>
                {/* Start Service Area */}
                <div className={`service-area ${this.props.horizontal}`}>
                    <div className="container">

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <h2 className="title">
                                Terms and Conditions
                            </h2>
                        </div>
                        
                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    1.	Introduction
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                    These App Standard Terms and Conditions written on this page shall manage your use of this App. These Terms will be applied fully and affect to your use of this App. By using this App, you agreed to accept all terms and conditions written in here. You must not use this App if you disagree with any of these App Standard Terms and Conditions.
                                </p>
                                <p>
                                    Minors or people below 18 years old must receive parental/guardian permission prior to downloading the mobile App.
                                </p>
                                <p>
                                    Minors under the age of 12 are not to download the mobile application
                                </p>
                            </div>
                        </div>

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    2.	Intellectual Property Rights
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                    Other than the content you own, under these Terms, M8thub Pty Ltd and/or its licensors own all the intellectual property rights and materials contained in this App.
                                </p>
                                <p>
                                    You are granted limited license only for purposes of viewing the material contained on this App.
                                </p>
                            </div>
                        </div>

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    3.	Restrictions
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                You are specifically restricted from all of the following
                                    <li>publishing any App material in any other media;</li>
                                    <li>selling, sublicensing and/or otherwise commercializing any App material;</li>
                                    <li>publicly performing and/or showing any App material;</li>
                                    <li>using this App in any way that is or may be damaging to this App;</li>
                                    <li>using this App in any way that impacts user access to this App;</li>
                                    <li>using this App contrary to applicable laws and regulations, or in any way may cause harm to the App, or to any person or business entity;</li>
                                    <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this App;</li>
                                    <li>using this App to engage in any advertising or marketing.</li>
                                </p>
                                <p>
                                    Certain areas of this App are restricted from being access by you and M8thub Pty Ltd may further restrict access by you to any areas of this App, at any time, in absolute discretion. Any user ID and password you may have for this App are confidential and you must maintain confidentiality as well.
                                </p>
                            </div>
                        </div>

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    4.	Your Content
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                    In these App Standard Terms and Conditions, “Your Content” shall mean any audio, video text, images or other material you choose to display on this App. By displaying Your Content, you grant M8thub Pty Ltd a non-exclusive, worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.
                                </p>
                                <p>
                                    Your Content must be your own and must not be invading any third-party’s rights. M8thub Pty Ltd reserves the right to remove any of Your Content from this App at any time without notice.
                                </p>
                            </div>
                        </div>

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    5.	No warranties
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                    This App is provided “as is,” with all faults, and M8thub Pty Ltd express no representations or warranties, of any kind related to this App or the materials contained on this App. Also, nothing contained on this App shall be interpreted as advising you.
                                </p>
                            </div>
                        </div>

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    6.	Limitation of liability
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                    In no event shall M8thub Pty Ltd, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of this App whether such liability is under contract.  M8thub Pty Ltd, including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of this App.
                                </p>
                            </div>
                        </div>

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    7.	Indemnification
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                    You hereby indemnify to the fullest extent M8thub Pty Ltd from and against any and/or all liabilities, costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.
                                </p>
                            </div>
                        </div>

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    8.	Severability
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                    If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.
                                </p>
                            </div>
                        </div>

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    9.	Variation of Terms
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                    M8thub Pty Ltd is permitted to revise these Terms at any time as it sees fit, and by using this App you are expected to review these Terms on a regular basis.
                                </p>
                            </div>
                        </div>

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    10.	Assignment
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                    The M8thub Pty Ltd is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.
                                </p>
                            </div>
                        </div>

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    11.	Entire Agreement
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                    These Terms constitute the entire agreement between M8thub Pty Ltd and you in relation to your use of this app, and supersede all prior agreements and understandings.
                                </p>
                            </div>
                        </div>

                        <div className="row"  style={{ marginBottom: 30, textAlign: "left" }}>
                            <div className="col-lg-3 service-column">
                                <h4 className="title">
                                    12.	Governing Law & Jurisdiction
                                </h4>
                            </div>
                            <div className="col-lg-9 service-column">
                                <p>
                                    These Terms will be governed by and interpreted in accordance with the laws of Western Australia, and you submit to the non-exclusive jurisdiction of the courts located in Western Australia for the resolution of any disputes.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
                {/* End Service Area */}
            </div>
        )
    }
}

export default Terms;

