import React, { Component } from "react";
import Navbar from './components/Navbar';
import HeroOlive2 from './components/HeroOlive2';
import About from './components/About';
import Terms from './components/Terms';
import Feature from './components/Feature';
import Download from './components/Download';
import Pricing from './components/Pricing';
import Testimonial from './components/Testimonial';
import Screenshot from './components/Screenshot';
import Blog from './components/Blog';
import Footer from './components/Footer';

class TermsAndConditions extends Component{
    render(){
        return(
            <div>
                {/* Header Navbar */}
                <Navbar/>

                {/* Slider */}
                <HeroOlive2 />

                {/* About */}
                {/* <About horizontalabout="horizontal-about" /> */}

                {/*Terms */}
                <Terms horizontal="horizontal" />
                
                {/*Feature */}
                {/* <Feature horizontalfeature="horizontal-feature" /> */}

                {/* Download */}
                {/* <Download horizontal="horizontal" /> */}
                
                {/* Pricing */}
                {/* <Pricing horizontalpricing="" /> */}
                
                {/* Pricing */}
                {/* <Testimonial /> */}
                
                {/* Screenshot */}
                {/* <Screenshot /> */}

                {/* Blog */}
                {/* <Blog /> */}
                
                {/* Footer */}
                <Footer horizontal="horizontal" />

            </div>
        )
    }
}

export default TermsAndConditions;
